<template>
  <div>
    <b-row class="p-3 border-bottom">
      <b-col>
        <h3>{{ $t("accountSettings.label.security") }}</h3>
      </b-col>
      <b-col class="m-auto text-right">
        <b-button
          pill
          variant="outline-primary"
          class="px-5 mr-2"
          :to="{ name: 'Security' }"
        >
          {{ $t("global.button.cancel") }} </b-button
        ><b-button pill variant="outline-primary" class="px-5 ml-2">
          {{ $t("global.button.save") }}
        </b-button>
      </b-col>
    </b-row>

    <div class="p-4">
      <!-- reset password -->
      <div class="my-2" v-bind:class="{ border: resetPassword }">
        <b-row
          class="border rounded w-100 m-0 p-2"
          v-bind:class="{ 'bg-primary text-white': resetPassword }"
        >
          <b-col>
            <b-form-checkbox v-model="resetPassword">
              {{ $t("accountSettings.label.resetPassword") }}
            </b-form-checkbox>
          </b-col>
          <b-col class="text-right"
            ><b-icon
              icon="chevron-down"
              v-show="!resetPassword"
              @click="resetPassword = !resetPassword"
            ></b-icon
            ><b-icon
              icon="chevron-up"
              v-show="resetPassword"
              @click="resetPassword = !resetPassword"
            ></b-icon
          ></b-col>
        </b-row>
        <div v-show="resetPassword" class="p-3">
          <form @submit.prevent="resetPasswordSubmit" class="w-100">
            <div class="col-md-10 m-auto">
              <h6>{{ verifyEmail }}</h6>
              <h6>{{ verifyPhone }}</h6>
              <label for="inputPassword4" class="form-label pl-2">{{
                $t("accountSettings.label.newPassword")
              }}</label>
              <input
                type="password"
                class="form-control input-pills"
                id="newPassword"
                v-model="newPassword"
                :class="{ 'is-invalid': submitted && !newPassword }"
              />
              <div v-show="submitted && !newPassword" class="invalid-feedback">
                {{ $t("global.message.fieldEmptyError") }}
              </div>
              <label for="inputPassword4" class="form-label pl-2 pt-3">{{
                $t("accountSettings.label.confirmNewPassword")
              }}</label>
              <input
                type="password"
                class="form-control input-pills"
                id="confirmNewPassword"
                v-model="confirmNewPassword"
                :class="{ 'is-invalid': submitted && !confirmNewPassword }"
              />
              <div
                v-show="submitted && !confirmNewPassword"
                class="invalid-feedback"
              >
                {{ $t("global.message.fieldEmptyError") }}
              </div>
            </div>
            <div class="col-10 pt-5 m-auto">
              <b-button
                pill
                size=""
                variant="outline-primary"
                class="w-100"
                type="submit"
              >
                {{ $t("accountSettings.button.updatePassword") }}
              </b-button>
            </div>
          </form>
        </div>
      </div>

      <div class="my-2" v-bind:class="{ border: twoFA }">
        <b-row
          class="border rounded w-100 m-0 p-2"
          v-bind:class="{ 'bg-primary text-white': twoFA }"
        >
          <b-col>
            <b-form-checkbox v-model="twoFA">
              {{ $t("accountSettings.label.twoFA") }}
            </b-form-checkbox>
          </b-col>
          <b-col class="text-right"
            ><b-icon
              icon="chevron-down"
              v-show="!twoFA"
              @click="twoFA = !twoFA"
            ></b-icon
            ><b-icon
              icon="chevron-up"
              v-show="twoFA"
              @click="twoFA = !twoFA"
            ></b-icon
          ></b-col>
        </b-row>
        <b-row v-show="twoFA" class="p-3">
          <b-col cols="12" class="py-1 text-center">
            <b-form-radio-group
              v-model="selected"
              :options="twoFAoptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </b-col>
        </b-row>
      </div>

      <div class="my-2" v-bind:class="{ border: preferredContaction }">
        <b-row
          class="border rounded w-100 m-0 p-2"
          v-bind:class="{ 'bg-primary text-white': preferredContaction }"
        >
          <b-col>
            <b-form-checkbox v-model="preferredContaction">
              {{ $t("accountSettings.label.preferredContact") }}
            </b-form-checkbox>
          </b-col>
          <b-col class="text-right"
            ><b-icon
              icon="chevron-down"
              v-show="!preferredContaction"
              @click="preferredContaction = !preferredContaction"
            ></b-icon
            ><b-icon
              icon="chevron-up"
              v-show="preferredContaction"
              @click="preferredContaction = !preferredContaction"
            ></b-icon
          ></b-col>
        </b-row>
        <b-row v-show="preferredContaction" class="p-3">
          <b-col cols="12" class="py-1 text-center">
            <b-form-radio-group
              v-model="selected"
              :options="contactWayOptions"
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      resetPassword: false,
      newPassword: null,
      confirmNewPassword: null,
      passwordNotMatch: true,
      twoFA: false,
      preferredContaction: false,

      submitted: false,

      twoFAoptions: [
        { item: "true", name: this.$t("accountSettings.label.twoFA") },
        { item: "false", name: this.$t("accountSettings.label.noTwoFA") }
      ],
      contactWayOptions: [
        { item: "email", name: this.$t("dashboard.label.email") },
        { item: "mobile", name: this.$t("dashboard.label.mobile") }
      ]
    };
  },
  methods: {
    show() {
      this.$refs.createNewPassword.show();
    },
    clearData() {
      this.$store.dispatch("verifyCode", null);
      this.$store.dispatch("verifyEmail", null);
      this.$store.dispatch("verifyPhone", null);
    },
    showSuccessMsg() {
      this.box = "";
      this.$bvModal.msgBoxOk("Password was updated successfully", {
        title: "Successfully",
        size: "sm",
        buttonSize: "sm",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0",
        bodyClass: "p-2 text-center",
        footerClass: "p-2 border-top-0",
        footerBgVariant: "",
        centered: true
      });
    },
    async resetPasswordSubmit() {
      this.submitted = true;
      const { newPassword, confirmNewPassword } = this;

      // stop here if form is invalid
      if (newPassword !== confirmNewPassword) {
        this.passwordNotMatch = false;
        return;
      }
      if (!(newPassword || confirmNewPassword)) return;

      if (this.verifyEmail) {
        await axios.post("user/ResetPasswordReset", {
          Token: this.verifyCode,
          Email: this.verifyEmail,
          Password: this.newPassword
        });
        this.clearData();
      } else if (this.verifyPhone) {
        await axios.post("user/ResetPasswordReset", {
          Token: this.verifyCode,
          Mobile: this.verifyPhone,
          Password: this.newPassword
        });
        this.clearData();
      }
      this.showSuccessMsg();
    }
  },
  computed: {
    ...mapGetters(["verifyCode"]),
    ...mapGetters(["verifyEmail"]),
    ...mapGetters(["verifyPhone"])
  }
};
</script>
